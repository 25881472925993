<template>
  <modal 
    class="center-zoomIn-transition v2 SelectUnavailableTime" 
    :scrollable="true" 
    width="100%" 
    height="auto" 
    :clickToClose="false" 
    :name="modalName" 
    @before-close="onBeforeClose($event)" 
    @before-open="onBeforeOpen($event)"
    @opened="onOpened($event)"
  >
    
    <div class="dialer-edit w-100">
      <div v-if="$store.getters.getIsMobile" class="dialer-edit-header mt-0 flex-column">
        <div class="d-flex w-100 justify-content-between align-items-center mb-2">
          <h2 class="dialer-edit-title">Select unavailable time</h2>
        </div>
        <div class="dialer-edit-actions d-flex justify-content-end w-100">
          <button class="newDoneButton" @click="onDone()">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div v-else class="dialer-edit-header b-0">
        <h2 class="dialer-edit-title">Select unavailable time</h2>
        <div class="dialer-edit-actions d-flex">
          <a class="newCloseButton" type="button" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <div class="m-4 mt-5 mb-5" ref="slider" />
      <div class="d-flex justify-content-between">
        <vue-ctk-date-time-picker 
          v-bind="ctkDateTimePickerOptions" 
          class="m-0"
          style="width: 35%"
          v-model="form.start" 
          @validate="onValidate()"
          format="YYYY-MM-DD HH:mm" 
          formatted="HH:mm" 
          onlyTime 
          noLabel 
          noClearButton
          noHeader
          inputSize="sm"
        />
        <vue-ctk-date-time-picker 
          v-bind="ctkDateTimePickerOptions"  
          class="m-0"
          style="width: 35%"
          v-model="form.end"
          @validate="onValidate()"
          format="YYYY-MM-DD HH:mm" 
          formatted="HH:mm" 
          onlyTime 
          noLabel 
          noClearButton
          noHeader
          inputSize="sm"
        />
      </div>
      <div class="d-flex justify-content-end w-100 mt-20px">
        <button class="fullWidthDoneButton" @click="onDone()">
          Update
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import moment from 'moment-timezone';
import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import { ctkDateTimePicker } from '@/mixin';
export default {
  name: "TimeSelectionModal",
  mixins: [ctkDateTimePicker],
  props: {
    modalName: {
      type: String,
      default: "TimeSelectionModal",
    },
  },
  data() {
    return {
      form: {
        start: '',
        end: '',
      },
      submitted: {
        done: false,
      },
    };
  },
  methods: {
    onDone() {
      this.submitted.done = true;
      let start = moment(this.form.start,'YYYY-MM-DD HH:mm')
      let end = moment(this.form.end,'YYYY-MM-DD HH:mm')
      const makeFormat = (value) => value.toString().length==1 ? `0${value}` : `${value}`
      this.$emit("updated", {
        start: {
          data: {
            HH: makeFormat(start.get('hour')),
            mm: makeFormat(start.get('minute')),
          },
        },
        end: {
          data: {
            HH: makeFormat(end.get('hour')),
            mm: makeFormat(end.get('minute')),
          },
        },
      });
      this.$modal.hide(this.modalName);
    },
    onBeforeOpen(event) {
      this.form.start = moment(`2023-12-12 ${event?.params?.start ?? '00:00:00'}`,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm');
      this.form.end = moment(`2023-12-12 ${event?.params?.end ?? '00:00:00'}`,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm');
    },
    onValidate(){
      const start_day = moment(`2023-12-12 00:00:00`,'YYYY-MM-DD HH:mm:ss').startOf('days')
      const start = moment(`2023-12-12 ${moment(this.form.start,'YYYY-MM-DD HH:mm').format('HH:mm:ss')}`,'YYYY-MM-DD HH:mm:ss')
      const end = moment(`2023-12-12 ${moment(this.form.end,'YYYY-MM-DD HH:mm').format('HH:mm:ss')}`,'YYYY-MM-DD HH:mm:ss')
      if(end.diff(start_day,'minutes')<start.diff(start_day,'minutes')) {
        this.form.start=start.format('YYYY-MM-DD HH:mm')
        this.form.end=start.format('YYYY-MM-DD HH:mm')
        this.$refs.slider.noUiSlider.set([
          start.diff(start_day,'minutes'),
          start.diff(start_day,'minutes'),
        ])
      } else {
        this.form.start=start.format('YYYY-MM-DD HH:mm')
        this.form.end=end.format('YYYY-MM-DD HH:mm')
        this.$refs.slider.noUiSlider.set([
          start.diff(start_day,'minutes'),
          end.diff(start_day,'minutes'),
        ])
      }
    },
    onOpened(){
      let vm = this
      const start_day = moment(`2023-12-12 00:00:00`,'YYYY-MM-DD HH:mm:ss').startOf('days')
      const start = moment(`2023-12-12 ${moment(this.form.start,'YYYY-MM-DD HH:mm').format('HH:mm:ss')}`,'YYYY-MM-DD HH:mm:ss')
      const end = moment(`2023-12-12 ${moment(this.form.end,'YYYY-MM-DD HH:mm').format('HH:mm:ss')}`,'YYYY-MM-DD HH:mm:ss')
      noUiSlider.create(this.$refs.slider, {
        start: [
          start.diff(start_day,'minutes'),
          end.diff(start_day,'minutes'),
        ],
        connect: true,
        step: 1,
        tooltips: [ true, true ],
        range: {
          'min': 0,
          'max': 1440
        },
        pips: {
          mode: 'steps',
          filter: (value) => (value % (60 * 4) === 0) ? 1 : (value % 60 === 0) ? 2 : 0,
          format: {
            to: (value) => value % (60 * 4)==0 ? moment().startOf('day').add(value, 'minutes').format('HH:mm') : '',
            from: (value) => value,
          }
        },
        format: {
          to: (value) => moment().startOf('day').add(value, 'minutes').format('HH:mm'),
          from: (value) => value,
        }
      });
      this.$refs.slider.noUiSlider.on("change", (values,handle,unencoded/*,tap,positions,noUiSlider*/) => {
        vm.form.start=moment(`2023-12-12 00:00:00`,'YYYY-MM-DD HH:mm:ss').startOf('day').add(unencoded[0], 'minutes').format('YYYY-MM-DD HH:mm')
        vm.form.end=moment(`2023-12-12 00:00:00`,'YYYY-MM-DD HH:mm:ss').startOf('day').add(unencoded[1], 'minutes').format('YYYY-MM-DD HH:mm')
      });
    },
    onBeforeClose() {
      this.submitted.done = false;
      this.form.start = '';
      this.form.end = '';
      this.$emit('before-close')
    },
  },
};
</script>

<style>
</style>