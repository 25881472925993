<template>
  <div ref="PowerDialerContainer" class="PowerDialerContainer">
    <h2 class="d-flex flex-wrap align-items-center justify-content-start">
      <div class="goToPreviousScreen" v-if="selected.sheet_id">
        <vb-svg @click="selected.sheet_id='';selected.numbers={};response.numbers=[];conditions.setting=false" class="cursor_pointer_no_hover"
          name="leftLongArrow-icon" 
          width="20" 
          height="12" 
          viewBox="0 0 30 16" 
          stroke-width="1" 
          stroke="" 
          fill="none" 
          stroke-linecap="round" 
          stroke-linejoin="round" 
        />
      </div>
      <span class="d-block title ml-2">Power dialer</span>
      <!-- <div v-if="selected.sheet_id" class="d-flex justify-content-center">
        <div v-if="$store.state.power_dialer.sheet_id==selected.sheet_id" @click="$store.state.power_dialer.sheet_id='';" class="startStopDialing">
          <vb-icon icon="PowerDialer-stop-icon" width="14px" height="14px" />
          <div class="startStopDialing-text">Stop call</div>
        </div>
        <div v-else @click="$store.state.power_dialer.sheet_id ? '' : startAutoDial()" class="startStopDialing">
          <vb-icon icon="PowerDialer-start-icon" width="11.79px" height="13.482px" />
          <div class="startStopDialing-text">Auto call</div>
        </div>
        <div v-if="$store.state.power_dialer.sheet_id==selected.sheet_id" class="d-flex">
          <b-checkbox 
            switch 
            :checked="$store.state.power_dialer.auto" 
            @change="$store.state.power_dialer.auto ? 
              $store.state.power_dialer.auto=false : 
              (
                $store.state.power_dialer.auto=true,
                nextCall()
              )
            " 
          />
          <button v-if="!$store.state.power_dialer.auto" @click="nextCall()">Next Call</button>
        </div>
        
        <b-button :disabled="api.fetch_sheet_numbers.send" class="p-0 ml-2" size="sm" type="button" variant="link" @click="openSetting()">
          <b-icon icon="gear-fill" variant="black" />
        </b-button>
      </div> -->
    </h2>
    <div v-if="!selected.sheet_id" class="PowerDialerContainer-inner">
      <div :class="`PowerDialer-row ${sheet.id == $store.state.power_dialer.sheet_id ? 'flashing' : ''}`" style="cursor: pointer;" v-for="sheet in response.sheets" :key="sheet.id" @click="selected.sheet_id=sheet.id;fetchNumbers()">
        <div class="PowerDialer-row-leftSide">
          <!-- <vb-icon icon="PowerDialer-contact-icon" class="PowerDialer-contact-icon"  /> -->
          <b-icon icon="file-earmark-text-fill" class="PowerDialer-contact-icon"  />
          <div>
            <div class="PowerDialer-row-mainText d-flex align-items-center">
              {{sheet.name}}
              <!-- <b-badge variant="primary">{{sheet.total_records}}</b-badge> -->
              <span class="AdmintaggedVersion blackAndWhite mb-0 ml-1 px-2">{{sheet.total_records}}</span>
            </div>
          </div>
        </div>
        <div class="PowerDialer-row-rightSide">
          <vb-icon icon="PowerDialer-rightArrow-icon" class="PowerDialer-rightArrow-icon" width="8.526" height="14.113" />
        </div>
      </div>
      <template v-if="api.fetch_sheets.send">
        <div class="PowerDialer-row" v-for="n in 5" :key="n.id">
          <div class="PowerDialer-row-leftSide" style="width:calc(100% - 40px)">
            <b-icon icon="file-earmark-text-fill" class="PowerDialer-contact-icon"  />
            <div class="" style="width:calc(100% - 40px)">
              <div class="PowerDialer-row-mainText name-chip w-100 mb-1" ><div class="latestShimmerDesign" style="height: 15px;width: 100%;"></div></div>
              <div class="PowerDialer-row-secondaryText w-100"><div class="latestShimmerDesign" style="height: 13px;width: 100%;"></div></div>
            </div>
          </div>
          <div class="PowerDialer-row-rightSide">
            <vb-icon icon="PowerDialer-phone-icon" class="PowerDialer-phone-icon" width="15.983px" height="16.002px" />
          </div>
        </div>
      </template>
      <div v-else-if="isEmpty(response.sheets)" class="emptyScreenContainer mt-32px b-0 p-0">
        <img width="50%" height="auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')"/>
        <img width="50%" height="auto" v-else :src="require('@/assets/images/emptyScreenImages/addressBook.png')"/>
        <div class="emptyScreenContainer-heading mt-32px">No record</div>
      </div>
    </div>
    <div v-else class="PowerDialerContainer-inner">
      <div v-if="conditions.setting" class="w-100 d-flex">
        <!-- <button class="newCloseButton" :disabled="api.clear_status.send" @click="clearStatus()">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Clear status</span>
        </button> -->
      </div>
      <div v-else class="smallCircularGraph-container">
        <div class="smallCircularGraph">
          <div class="smallCircularGraph-heading">Dialed</div>
          <div class="newProgressBar">
            <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${stateCount.dialed};--fg:#0FA2A9`">
              <div class="newProgressBar-inner">
                {{stateCount.dialed}}%
              </div>
            </div>
          </div>
        </div>
        <div class="smallCircularGraph">
          <div class="smallCircularGraph-heading">Canceled</div>
          <div class="newProgressBar">
            <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${stateCount.canceled};--fg:#FC9E1B`">
              <div class="newProgressBar-inner">
                {{stateCount.canceled}}%
              </div>
            </div>
          </div>
        </div>
        <div class="smallCircularGraph">
          <div class="smallCircularGraph-heading">Missed</div>
          <div class="newProgressBar">
            <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${stateCount.missed};--fg:#EF2E33`">
              <div class="newProgressBar-inner">
                {{stateCount.missed}}%
              </div>
            </div>
          </div>
        </div>
        <div class="smallCircularGraph w-50">
          <div class="smallCircularGraph-heading">Dialing</div>
          <div class="newProgressBar">
            <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${stateCount.dialing};--fg:#00C268`">
              <div class="newProgressBar-inner">
                {{stateCount.dialing}}%
              </div>
            </div>
          </div>
        </div>
        <div class="smallCircularGraph w-50">
          <div class="smallCircularGraph-heading">Pending</div>
          <div class="newProgressBar">
            <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${stateCount.pending};--fg:black;`">
              <div class="newProgressBar-inner">
                <span>{{stateCount.pending}}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!conditions.setting" class="d-flex align-items-center justify-content-between mb-20px">
        <div class="d-flex align-items-center">
          <b-checkbox v-if="$store.state.power_dialer.sheet_id==selected.sheet_id"
            switch class="newerSwitch"
            :checked="$store.state.power_dialer.auto" 
            @change="$store.state.power_dialer.auto ? 
              $store.state.power_dialer.auto=false : 
              (
                $store.state.power_dialer.auto=true,
                nextCall()
              )
            " 
          />
          <div class="autoSwitchText">Auto</div>
        </div>
        <b-button :disabled="api.fetch_sheet_numbers.send" class="clearButton ml-auto" size="sm" type="button" variant="link" @click="openSetting()">
          <vb-icon icon="powerDilerRightSide-clear-icon" width="21.84" height="18.719"/>
          Clear
        </b-button>
      </div>
      <div class="PowerDialer-rows-container">
        <div :class="`PowerDialer-row ${number.state=='dialing' ? 'flashing' : ''}`" v-for="number in numbers" :key="number.id">
          <div :class="`PowerDialer-row-leftSide ${conditions.setting ? 'insideSettings' : ''}`">
            <b-button 
              v-if="conditions.setting" 
              :disabled="api.clear_status.send"
              type="button" 
              variant="link" 
              class="p-0 mr-2"
              @click="selected.numbers[number.row_id] ? $delete(selected.numbers,number.row_id) : $set(selected.numbers,number.row_id,number.row_id)"
            >
              <b-icon v-if="selected.numbers[number.row_id]" icon="check-square-fill" variant="black" />
              <b-icon v-else icon="square" variant="black" />
            </b-button>
            <vb-icon icon="PowerDialer-contact-icon" class="PowerDialer-contact-icon"  />
            <div class="insideSettings-text-container">
              <div class="PowerDialer-row-mainText name-chip" v-b-tooltip="number.name">{{number.name}}</div>
              <div :class="`PowerDialer-row-secondaryText ${number.state=='dialing' ? 'highlightIt' : '' }`">{{number.number | number_formater}}</div>
              <div v-if="number.state" class="PowerDialer-row-verySmallText">
                <span :class="`${number.state}`">{{number.state}}</span>
                <div v-if="number.state=='dialing'" class="d-flex">
                  <div v-if="number.dialed_by!=getCurrentUser.account" class="d-flex mr-2">
                    <span class="mr-1">by</span>
                    <Info v-if="number.dialed_by" :id="number.dialed_by" :only_name="true" />
                  </div>
                  <div class="dialingCircles">
                    <div class="dialingCircle"></div>
                    <div class="dialingCircle"></div>
                    <div class="dialingCircle"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!conditions.setting" class="PowerDialer-row-rightSide">
            <!-- <b-icon icon="info-circle-fill" @click="$modal.show(`${_uid}-ContactInfo`,{ row_no: number.row_id, sheet_id: number.sheet_id })" class="PowerDialer-phone-icon" width="15.983px" height="16.002px" variant="black" /> -->
            <vb-icon icon="powerDilerRightSideInfo-icon" width="22.74px" height="22.74px" @click="$modal.show(`${_uid}-ContactInfo`,{ row_no: number.row_id, sheet_id: number.sheet_id })" class="PowerDialer-phone-icon"/>
            <vb-icon icon="PowerDialer-phone-icon" @click="$store.state.power_dialer.sheet_id || number.state=='dialing' ? '' : dialPowerDialerNumber(number)" class="PowerDialer-phone-icon onlyPhoneIcon" :class="`${number.state=='dialing' || $store.state.power_dialer.sheet_id?'disabled':''}`" width="15.983px" height="16.002px" />
          </div>
        </div>
      </div>
      <template v-if="api.fetch_sheet_numbers.send">
        <div class="PowerDialer-row" v-for="n in 5" :key="n.id">
          <div class="PowerDialer-row-leftSide" style="width:calc(100% - 40px)">
            <vb-icon icon="PowerDialer-contact-icon" class="PowerDialer-contact-icon"  />
            <div class="" style="width:calc(100% - 40px)">
              <div class="PowerDialer-row-mainText name-chip w-100 mb-1" ><div class="latestShimmerDesign" style="height: 15px;width: 100%;"></div></div>
              <div class="PowerDialer-row-secondaryText w-100"><div class="latestShimmerDesign" style="height: 13px;width: 100%;"></div></div>
            </div>
          </div>
          <div class="PowerDialer-row-rightSide">
            <vb-icon icon="PowerDialer-phone-icon" class="PowerDialer-phone-icon" width="15.983px" height="16.002px" />
          </div>
        </div>
      </template>
      <div v-else-if="isEmpty(numbers)" class="emptyScreenContainer mt-32px b-0 p-0">
        <img width="50%" height="auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')"/>
        <img width="50%" height="auto" v-else :src="require('@/assets/images/emptyScreenImages/addressBook.png')"/>
        <div class="emptyScreenContainer-heading mt-32px">No record</div>
      </div>
      <template v-if="conditions.setting">
        <button class="clearStatusFullWidthButton mt-12px" :disabled="api.clear_status.send" @click="clearStatus()">
          Clear status
        </button>
      </template>
      <template  v-if="selected.sheet_id">
        <div class="d-flex mt-20px" style="gap: 10px">
          <b-button variant="primary" type="button" class="AutoCallInfo-button-grey w-50" v-if="$store.state.power_dialer.sheet_id==selected.sheet_id" @click="$store.state.power_dialer.sheet_id='';">
            <vb-icon icon="powerDiler-stopAutoDialing-icon" height="26px" width="26px" />
            Stop call
          </b-button>
          <b-button variant="primary" type="button" class="AutoCallInfo-button-grey w-50" v-else @click="$store.state.power_dialer.sheet_id ? '' : startAutoDial()">
            <vb-icon icon="powerDiler-startAutoDialing-icon" height="26px" width="26px" />
            Auto call
          </b-button>
          <b-button variant="primary" type="button" class="AutoCallInfo-button w-50" v-if="!$store.state.power_dialer.auto" @click="nextCall()">
            Next Call
          </b-button>
        </div>
        <div class="d-flex justify-content-center">
          <!-- <div v-if="$store.state.power_dialer.sheet_id==selected.sheet_id" @click="$store.state.power_dialer.sheet_id='';" class="startStopDialing">
            <vb-icon icon="PowerDialer-stop-icon" width="14px" height="14px" />
            <div class="startStopDialing-text">Stop call</div>
          </div>
          <div v-else @click="$store.state.power_dialer.sheet_id ? '' : startAutoDial()" class="startStopDialing">
            <vb-icon icon="PowerDialer-start-icon" width="11.79px" height="13.482px" />
            <div class="startStopDialing-text">Auto call</div>
          </div> -->
          <!-- <div v-if="$store.state.power_dialer.sheet_id==selected.sheet_id" class="d-flex">
            <b-checkbox 
              switch 
              :checked="$store.state.power_dialer.auto" 
              @change="$store.state.power_dialer.auto ? 
                $store.state.power_dialer.auto=false : 
                (
                  $store.state.power_dialer.auto=true,
                  nextCall()
                )
              " 
            />
            <button v-if="!$store.state.power_dialer.auto" @click="nextCall()">Next Call</button>
          </div> -->
          
          <!-- <b-button :disabled="api.fetch_sheet_numbers.send" class="p-0 ml-2" size="sm" type="button" variant="link" @click="openSetting()">
            <b-icon icon="gear-fill" variant="black" />
          </b-button> -->
        </div>
      </template>
    </div>
    <PowerDialerContactInfoModal :modalName="`${_uid}-ContactInfo`" />
  </div>
</template>

<script>
import { events, VOIP_API } from '@/utils';
import Info from '@/components/Lists/Info.vue';
import PowerDialerContactInfoModal from '@/components/Modals/PowerDialerContactInfoModal.vue';
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
import _ from 'lodash'
export default {
  name: 'PowerDialer',
  components: {
    Info,
    PowerDialerContactInfoModal,
  },
  inject: [
    'appNotify',
    'isEmpty', 
  ],
  data() {
    return {
      api: {
        fetch_sheets: this.$helperFunction.apiInstance(),
        fetch_sheet_numbers: this.$helperFunction.apiInstance(),
        clear_status: this.$helperFunction.apiInstance(),
      },
      response: {
        sheets: [],
        numbers: {},
      },
      selected: {
        sheet_id: '',
        numbers: {},
      },
      conditions: {
        setting: false
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      GET_LOCAL_SETTING_IS_DARK_MODE,
    ]),
    numbers(){ 
      if(this.conditions.setting) {
        return Object.values(this.response.numbers).filter(i=>!!i.state && i.state!='dialing')
      }
      return Object.values(this.response.numbers) 
    },
    stateCount(){
      const numbers = Object.values(this.response.numbers)
      const states =  _.groupBy(numbers, 'state')
      return {
        dialed: Math.round((states?.dialed?.length ?? 0)/numbers.length*100) || 0, 
        canceled: Math.round((states?.canceled?.length ?? 0)/numbers.length*100) || 0, 
        missed: Math.round((states?.missed?.length ?? 0)/numbers.length*100) || 0, 
        dialing: Math.round((states?.dialing?.length ?? 0)/numbers.length*100) || 0,
        pending: Math.round((states?.null?.length ?? 0)/numbers.length*100) || 0,
      }
    },
    isInCall(){
      const sip_sessions = !_.isEmpty(this.$store.state.sip.phone) ? this.$store.state.sip.phone.getSessions() || [] : []
      const jitsi_outgoings = !_.isEmpty(this.$store.state.calls.outgoing_calls) ? this.$store.state.calls.outgoing_calls : []
      const jitsi_incomings = !_.isEmpty(this.$store.state.calls.incoming_calls) ? this.$store.state.calls.incoming_calls : []
      const jitsi_videocalls = !_.isEmpty(this.$store.state.calls.videoCalls) ? this.$store.state.calls.videoCalls : []
      const new_sip_sessions = sip_sessions
      return !_.isEmpty(new_sip_sessions) || !_.isEmpty(jitsi_outgoings) || !_.isEmpty(jitsi_incomings) || !_.isEmpty(jitsi_videocalls)
    },
  },
  methods: {
    openSetting(){
      const selected_numbers = Object.values(this.response.numbers).filter(i=>!!i.state)
      this.selected.numbers=_.mapValues(_.keyBy(selected_numbers,'row_id'),'row_id')
      this.conditions.setting=!this.conditions.setting
    },
    async clearStatus(){
      if(this.api.clear_status.send) return;
      try {
        this.api.clear_status.send=true
        await VOIP_API.endpoints.power_dialer.deleteStatus({
          sheet_id: this.selected.sheet_id,
          row_no: Object.values(this.selected.numbers)
        })
        this.fetchNumbers()
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.clear_status.send=false
      }
    },
    async fetchNumbers(){
      if(this.api.fetch_sheet_numbers.send) return;
      try {
        this.api.fetch_sheet_numbers.send=true
        const { data } = await VOIP_API.endpoints.power_dialer.getSheetNumbers(this.selected.sheet_id)
        this.response.numbers=data ?? {}
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_sheet_numbers.send=false
      }
    },
    async fetchSheets() {
      if(this.api.fetch_sheets.send) return;
      try {
        this.api.fetch_sheets.send=true
        const { data } = await VOIP_API.endpoints.power_dialer.getSheets()
        this.response.sheets = data
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_sheets.send=false
      }
    },
    startAutoDial(){
      if(!this.isInCall) {
        this.$store.state.power_dialer.sheet_id=this.selected.sheet_id;
        this.$store.state.power_dialer.auto=true;
        this.$emit('pinned')
      } else {
        this.appNotify({
          message: 'You already in a call',
          type: 'danger'
        })
      }
    },
    dialPowerDialerNumber(number){
      const row_no = number.row_id
      const sheet_id = this.selected.sheet_id
      if(!this.$store.state.power_dialer.sheet_id) {
        this.$root.$emit(events.audio_call,{
          call_type: 'power_dialer',
          row_no: row_no,
          sheet_id: sheet_id
        })
        this.$emit('pinned')
      }
    },
    nextCall(){
      if(!this.isInCall) {
        this.$root.$emit(events.audio_call,{
          call_type: 'power_dialer',
        })
      } else {
        this.appNotify({
          message: 'You already in a call',
          type: 'danger'
        })
      }
    },
    onNotification({ data }){
      const { type, sheet_id, row_no, value, published_by } = data
      if(type=='PD_STATUS' && sheet_id==this.selected.sheet_id && this.response.numbers[row_no]) {
        this.response.numbers[row_no].state=value
        this.response.numbers[row_no].dialed_by=published_by
      }
    },
  },
  created(){
    AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification.bind(this))
  },
  mounted(){
    this.fetchSheets()
  },
  beforeDestroy(){
    AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification.bind(this))
  }
}
</script>

<style lang="scss" scoped>
.name-chip {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>