var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_c('div', {
    staticClass: "position-relative panel-wide d-flex flex-column applyLeftPadding",
    attrs: {
      "id": "dialpadPanel"
    }
  }, [_c('span', {
    class: `main position-relative h-100`
  }, [_c('div', {
    staticClass: "dial-pad-wrapper v2 justify-content-between flex-column d-flex h-100",
    class: _vm.anyCall ? 'pt-0' : 'bg-white justify-content-between py-4',
    staticStyle: {
      "border-radius": "24px"
    }
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "w-100 d-flex justify-content-start dialer-section-1",
    style: `height:calc(100vh - ${_vm.mobileNumPad_height}px - 52px)`
  }, [_vm.getIsMobile ? _c('div', {
    class: `chat-info forMobile w-100 ${_vm.getProperty(_vm.$tours, `app-tour.data.currentStep`) == 0 ? 'p-3 bg-white' : ''} ${!_vm.isEmpty(_vm.sipsessions) || _vm.$store.state.calls.videoCalls.length >= 1 ? '' : 'py-0'}`,
    attrs: {
      "app-tour-step": "0"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column position-relative"
  }, [_vm.anyCall ? _c('div', {
    staticClass: "backToCallButton text-center mb-2",
    on: {
      "click": function ($event) {
        _vm.$store.state.global_conditions.tab = 'video-call';
      }
    }
  }, [_vm.allIncomingCalls.length ? [_vm._v(_vm._s(_vm.allIncomingCalls.length) + " "), _c('b-icon', {
    attrs: {
      "icon": "telephone-inbound-fill"
    }
  })] : _vm._e(), _vm._v(" " + _vm._s(_vm.sipsessions.length) + " Active calls ")], 2) : _vm._e(), _c('div', {
    staticClass: "dialerMainMobile-topBar"
  }, [_c('div', {
    staticClass: "CountryPlusFlag-onTopOfDialer"
  }, [_c('label', [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedCallerId, 'number_labels.name')))]), _vm.isIntegration ? _c('label', {
    staticClass: "ml-1"
  }, [_vm._v("on " + _vm._s(_vm.$store.state.integration_platform))]) : _vm._e()]), _c('div', {
    staticClass: "dialer-dropdown dialer-selectNumber-dropdown"
  }, [_c('b-dropdown', {
    staticClass: "m-2 w-100",
    attrs: {
      "text": _vm.$store.state.sip.caller_id ? _vm.$store.state.sip.caller_id : 'Select a number',
      "id": "set-design-dd"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          attrs: {
            "icon": "callerId-mobile"
          }
        }), _c('span', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "mr-3 text-center"
        }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(_vm.selectedCallerId, 'caller_id', 'Please select a caller ID'))))]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3477491507)
  }, [_c('h6', {
    staticClass: "selectNumber-heading mb-0"
  }, [_vm._v("Your number")]), _c('b-form-radio-group', {
    staticClass: "radio_btn_like_checkbox",
    attrs: {
      "checked": _vm.$store.state.sip.caller_id,
      "disabled": _vm.api.update_caller_id.send,
      "options": _vm.numberOptions
    },
    on: {
      "change": function ($event) {
        return _vm.updateCallerId($event);
      }
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between minHeight-50px",
    attrs: {
      "app-tour-web-dashboard-dialer-input": ""
    }
  }, [_c('div', {
    staticClass: "flex-grow-1 position-relative"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$store.state.sip.number,
      expression: "$store.state.sip.number"
    }],
    staticClass: "d-block w-100 border-0",
    attrs: {
      "placeholder": "Enter number...",
      "type": "tel"
    },
    domProps: {
      "value": _vm.$store.state.sip.number
    },
    on: {
      "focus": function ($event) {
        _vm.conditions.number_input_focused = true;
      },
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.$store.state.sip, "number", $event.target.value);
      }, function ($event) {
        return _vm.selected.contact_to_dial.reset();
      }],
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.dial();
      }
    }
  })]), _c('div', {
    staticClass: "flex-shrink-1 dashboard-call-cancel-icons"
  }, [_c('ul', {
    staticClass: "list-unstyled mb-0 icon-list"
  }, [_vm.$store.state.sip.number ? _c('li', {
    staticClass: "d-inline-block px-1"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number = '';
        _vm.conditions.number_input_focused = false;
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "mobileDialerNumberReset-updated-icon",
    attrs: {
      "icon": "mobileDialerNumberReset-updated-icon",
      "width": "20",
      "height": "20"
    }
  })], 1)]) : _vm._e()])])]), _c('label', {
    staticClass: "countryText-inMobile"
  }, [_vm.numberCountryInfo.image ? _c('img', {
    attrs: {
      "src": _vm.numberCountryInfo.image
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.numberCountryInfo.country) + " " + _vm._s(_vm.numberCountryInfo.time ? `It's ${_vm.numberCountryInfo.time} there` : '') + " ")]), _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.conditions.number_input_focused && !_vm.isEmpty(_vm.searchNumber),
      expression: "conditions.number_input_focused && !isEmpty(searchNumber)"
    }],
    staticClass: "searchedNumbers-list"
  }, [_c('label', {
    staticClass: "suggestionsList-heading"
  }, [_vm._v("Suggestions")]), _vm._l(_vm.searchNumber, function (data, index) {
    return _c('div', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.selectContact(data);
        }
      }
    }, [_c('Info', {
      attrs: {
        "is_blf": false,
        "name": data.name,
        "sub_info": data.number,
        "image": data.image
      }
    })], 1);
  })], 2), !_vm.isIntegration ? [_c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.numberInfo.valid && _vm.numberInfo.is_mobile,
      expression: "numberInfo.valid && numberInfo.is_mobile"
    }],
    staticClass: "dialer-sms-area dialer-sms-area-updated position-relative"
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.sendsms();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-sms active"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.sms.sms,
      expression: "forms.sms.sms"
    }],
    staticClass: "focused-t",
    attrs: {
      "disabled": _vm.api.send_sms.send,
      "onfocus": "this.classList.add('focused')",
      "onblur": "this.classList.remove('focused')",
      "placeholder": "Enter your SMS message",
      "rows": "1",
      "maxlength": "160"
    },
    domProps: {
      "value": _vm.forms.sms.sms
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.sms, "sms", $event.target.value);
      }, function ($event) {
        $event.target.rows = ($event.target.value.match(/(\r|\n)/g) || []).length + 1;
        $event.target.rows = $event.target.scrollHeight > $event.target.offsetHeight ? Math.ceil($event.target.scrollHeight / 24) : $event.target.rows;
      }]
    }
  }), _vm.forms.sms.is_submitted ? [_vm.$v.forms.sms.sms.$invalid ? _c('p', [!_vm.$v.forms.sms.sms.required ? _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("* SMS is required")]) : _vm._e()]) : _vm._e()] : _vm._e(), _c('div', {
    staticClass: "dialer-sms-count"
  }, [_c('span', {
    staticClass: "dialer-sms-char-count"
  }, [_vm._v(_vm._s(`${160 - _vm.forms.sms.sms.length}`))]), _c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.send_sms.send || _vm.api.checksmsbalance.send || _vm.api.checksmsbalance.status == 2
    }
  }, [_vm.api.send_sms.send ? _c('vb-spinner') : [_vm._v("Send")]], 2)])], 2)])])])] : _vm._e()], 2)]) : _vm._e()]) : _vm._e(), !_vm.getIsMobile ? _c('div', {
    staticClass: "w-100 d-flex justify-content-start dialer-section-1"
  }, [!_vm.getIsMobile ? _c('div', {
    staticClass: "chat-info w-100",
    class: [{
      'p-3 bg-white': _vm.getProperty(_vm.$tours, `app-tour.data.currentStep`) == 0
    }, !_vm.isEmpty(_vm.sipsessions) || _vm.$store.state.calls.videoCalls.length >= 1 ? '' : 'py-0'],
    attrs: {
      "app-tour-step": "0"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column position-relative"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between minHeight-50px",
    attrs: {
      "app-tour-web-dashboard-dialer-input": ""
    }
  }, [_c('div', {
    staticClass: "CountryPlusFlag-onTopOfDialer align-items-center",
    staticStyle: {
      "width": "calc(100% - 174px)"
    }
  }, [_c('label', {
    staticClass: "cursor_pointer_no_hover align-items-center w-100",
    staticStyle: {
      "gap": "6px"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-callSMSpricing`, {
          number: _vm.$store.state.sip.number
        });
      }
    }
  }, [_vm.numberCountryInfo.image ? _c('img', {
    attrs: {
      "src": _vm.numberCountryInfo.image
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.numberCountryInfo.country) + " "), _c('span', {
    staticClass: "text-center",
    staticStyle: {
      "flex": "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.numberCountryInfo.time ? `It's ${_vm.numberCountryInfo.time} there` : ''))])]), _vm.isIntegration ? _c('label', [_vm._v(_vm._s(_vm.$store.state.integration_platform || 'test'))]) : _vm._e()]), _c('div', {
    staticClass: "flex-grow-1 position-relative"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$store.state.sip.number,
      expression: "$store.state.sip.number"
    }],
    ref: "number-input",
    staticClass: "d-block w-100 border-0",
    attrs: {
      "placeholder": "Enter number...",
      "type": "tel"
    },
    domProps: {
      "value": _vm.$store.state.sip.number
    },
    on: {
      "focus": function ($event) {
        _vm.conditions.number_input_focused = true;
      },
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.$store.state.sip, "number", $event.target.value);
      }, function ($event) {
        return _vm.selected.contact_to_dial.reset();
      }],
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.dial();
      }
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.conditions.number_input_focused && !_vm.isEmpty(_vm.searchNumber),
      expression: "conditions.number_input_focused && !isEmpty(searchNumber)"
    }],
    staticClass: "dialer-dropdown position-absolute top-35 dialer-fetchData-drop-down fullWidthNumberLookupDropDown"
  }, [_c('div', {
    staticClass: "dropdown"
  }, [_c('ul', {
    staticClass: "dropdown-menu show"
  }, _vm._l(_vm.searchNumber, function (data, index) {
    return _c('li', {
      key: index,
      staticClass: "mb-8px",
      attrs: {
        "id": data.id
      },
      on: {
        "click": function ($event) {
          return _vm.selectContact(data);
        }
      }
    }, [_c('Info', {
      attrs: {
        "is_blf": false,
        "name": data.name,
        "sub_info": data.number,
        "image": data.image
      }
    })], 1);
  }), 0)])])]), _c('div', {
    staticClass: "flex-shrink-1 dashboard-call-cancel-icons"
  }, [_c('ul', {
    staticClass: "list-unstyled mb-0 icon-list"
  }, [!_vm.isIntegration && _vm.numberInfo.valid && _vm.numberInfo.is_mobile ? _c('li', {
    staticClass: "d-inline-block px-1"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SendSmsModal', {
          number: _vm.$store.state.sip.number
        });
      }
    }
  }, [_c('vb-icon', {
    staticClass: "mainDialer-SMS-icon",
    attrs: {
      "icon": "mainDialer-SMS-icon"
    }
  })], 1)]) : _vm._e(), _vm.$store.state.sip.number ? _c('li', {
    staticClass: "d-inline-block px-1"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number = '';
        _vm.conditions.number_input_focused = false;
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-svg', {
    staticClass: "dialerColse_icon",
    attrs: {
      "name": "dialer-close-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1)]) : _vm._e(), (_vm.numberInfo.valid || _vm.selected.contact_to_dial.id) && !_vm.getIsMobile ? _c('li', {
    staticClass: "d-inline-block px-1"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.dial();
      }
    }
  }, [_c('vb-svg', {
    staticClass: "dialerCall_icon",
    attrs: {
      "name": "dialer-call-icon",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1)]) : _vm._e()])])]), _c('div', {
    staticClass: "dialer-dropdown dialer-selectNumber-dropdown with-copyCallerIDbutton w-100 mt-4",
    attrs: {
      "app-tour-web-dashboard-dialer-select-cid": ""
    }
  }, [_c('b-dropdown', {
    staticClass: "m-2 w-100",
    attrs: {
      "text": _vm.$store.state.sip.caller_id ? _vm.$store.state.sip.caller_id : 'Select a Number',
      "id": "set-design-dd"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex w-100 align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-start",
          staticStyle: {
            "width": "40%"
          }
        }, [_c('vb-svg', {
          staticClass: "selectPhone_icon",
          attrs: {
            "name": "selectPhone-icon",
            "width": "15.504",
            "height": "30.214",
            "viewBox": "0 0 15.504 30.214",
            "stroke-width": "0",
            "stroke": "#005DFF",
            "fill": "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        }), _vm.selectedCallerId && _vm.selectedCallerId.number_labels && _vm.selectedCallerId.number_labels.name ? _c('p', {
          staticClass: "OwnertaggedVersion mb-0 ml-0 caller_id_label position-relative mb-0 ml-3",
          staticStyle: {
            "top": "unset",
            "transform": "none",
            "left": "unset",
            "width": "calc(100% - 20px) !important"
          }
        }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedCallerId, 'number_labels.name')))]) : _vm._e()], 1), _c('span', {
          staticClass: "d-flex align-items-center justify-content-between pl-2",
          staticStyle: {
            "width": "60%"
          }
        }, [_c('span', {
          staticClass: "mr-3 text-left"
        }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(_vm.selectedCallerId, 'caller_id', _vm.api.caller_ids.send ? 'Your Numbers' : 'No outgoing number assigned'))))]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)])];
      },
      proxy: true
    }], null, false, 2366911535)
  }, [_vm.numberOptions.length == 0 ? [_c('b-dropdown-item', {
    staticClass: "border-bottom"
  }, [_vm._v(" No telephone numbers to list. ")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.showAddNumberInfoToaster();
      }
    }
  }, [_c('span', {
    staticClass: "latestBlueColor text-decoration-underline"
  }, [_vm._v(" Click here to get a telephone number ")])])] : [_c('h6', {
    staticClass: "selectNumber-heading mb-0"
  }, [_vm._v("Your number")]), _c('b-form-radio-group', {
    staticClass: "radio_btn_like_checkbox",
    attrs: {
      "disabled": _vm.api.update_caller_id.send,
      "checked": _vm.$store.state.sip.caller_id
    },
    on: {
      "change": function ($event) {
        return _vm.updateCallerId($event);
      }
    }
  }, _vm._l(_vm.numberOptions, function (data) {
    return _c('b-form-radio', {
      key: data.id,
      attrs: {
        "value": data.value
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm._v(" " + _vm._s(data.text) + " "), data.label ? _c('span', {
      staticClass: "OwnertaggedVersion mb-0 ml-16px"
    }, [_vm._v(_vm._s(data.label))]) : _vm._e()])]);
  }), 1)]], 2), _c('div', {
    staticClass: "copyCallerIDbutton"
  }, [_c('b-icon', {
    attrs: {
      "icon": "files"
    },
    on: {
      "click": function ($event) {
        return _vm.copyCallerId();
      }
    }
  })], 1)], 1)])]) : _vm._e()]) : _vm._e(), !_vm.getIsMobile ? [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.api.caller_ids.send && _vm.isEmpty(_vm.response.caller_ids),
      expression: "!api.caller_ids.send && isEmpty(response.caller_ids)"
    }],
    staticClass: "rounded-border-error-container"
  }, [_c('div', {
    staticClass: "rounded-border-error-container-inner"
  }, [_c('b-icon', {
    attrs: {
      "icon": "exclamation-circle-fill"
    }
  }), _c('div', {
    staticClass: "rounded-border-error-text flex-fill"
  }, [_vm._v(" You haven't yet been assigned an outgoing number to your account. Please request your account admin to assign a outgoing number. ")])], 1)]), _c('div', {
    class: `dial-icons-wrapper pt-0 mb-2 w-100 mw-100 justify-content-between ${_vm.getProperty(_vm.$tours, `app-tour.data.currentStep`) == 1 ? 'p-3 bg-white' : ''}`,
    attrs: {
      "app-tour-web-dashboard-dialer-dialpad": "",
      "app-tour-step": "1"
    }
  }, [_c('div', {
    staticClass: "main-dialpad dialpad d-flex flex-wrap align-items-center mx-auto"
  }, [_c('button', {
    staticClass: "btn-key notCallBTN d-flex border-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('1');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialOne-icon-v2"
    }
  })], 1), _c('button', {
    staticClass: "btn-key notCallBTN d-flex border-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('2');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialTwo-icon-v2"
    }
  })], 1), _c('button', {
    staticClass: "btn-key notCallBTN d-flex border-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('3');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialThree-icon-v2"
    }
  })], 1), _c('button', {
    staticClass: "btn-key notCallBTN four d-flex border-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('4');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialFour-icon-v2"
    }
  })], 1), _c('button', {
    staticClass: "btn-key notCallBTN d-flex border-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('5');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialFive-icon-v2"
    }
  })], 1), _c('button', {
    staticClass: "btn-key notCallBTN d-flex border-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('6');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialSix-icon-v2"
    }
  })], 1), _c('button', {
    staticClass: "btn-key notCallBTN d-flex border-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('7');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialSeven-icon-v2"
    }
  })], 1), _c('button', {
    staticClass: "btn-key notCallBTN d-flex border-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('8');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialEight-icon-v2"
    }
  })], 1), _c('button', {
    staticClass: "btn-key notCallBTN d-flex border-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('9');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialNine-icon-v2"
    }
  })], 1), _c('button', {
    staticClass: "btn-key notCallBTN d-flex border-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('*');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "asterik-icon-v3",
    attrs: {
      "icon": "asterik-icon-v3"
    }
  })], 1), _c('button', {
    staticClass: "btn-key notCallBTN d-flex border-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('0');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialZero-icon-v2"
    }
  })], 1), _c('button', {
    staticClass: "btn-key notCallBTN d-flex border-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('#');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "hash-icon-v3",
    attrs: {
      "icon": "hash-icon-v3"
    }
  })], 1), _c('button', {
    staticClass: "btn-key call-btn-key d-flex border-0 mb-0",
    on: {
      "click": function ($event) {
        return _vm.dial();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialPad-call-icon"
    }
  })], 1), _c('button', {
    staticClass: "btn-key notCallBTN call-btn-key backspace-icon d-flex justify-content-end align-items-center border-0 mb-0 mr-0",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number = _vm.$store.state.sip.number.substr(0, _vm.$store.state.sip.number.length - 1);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-backspace-icon-v3-mobile",
      "height": "30.128",
      "width": "51.975"
    }
  })], 1)])])] : _vm._e(), _vm.getIsMobile ? [_vm.getIsMobile || _vm.isEmpty(_vm.sipsessions) && _vm.isEmpty(_vm.$store.state.calls.videoCalls) ? _c('div', {
    staticClass: "mobileNumPad-likeAirCall-container",
    attrs: {
      "id": "mobileNumPad"
    }
  }, [_c('div', {
    staticClass: "mobileNumPad-likeAirCall-innerContainer"
  }, [_c('div', {
    staticClass: "mobileNumPad-likeAirCall-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('1');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-bigNumber"
  }, [_vm._v("1")]), _c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-smallNumber"
  }, [_vm._v(" ")])]), _c('div', {
    staticClass: "mobileNumPad-likeAirCall-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('2');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-bigNumber"
  }, [_vm._v("2")]), _c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-smallNumber"
  }, [_vm._v("A B C")])]), _c('div', {
    staticClass: "mobileNumPad-likeAirCall-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('3');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-bigNumber"
  }, [_vm._v("3")]), _c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-smallNumber"
  }, [_vm._v("D E F")])]), _c('div', {
    staticClass: "mobileNumPad-likeAirCall-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('4');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-bigNumber"
  }, [_vm._v("4")]), _c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-smallNumber"
  }, [_vm._v("G H I")])]), _c('div', {
    staticClass: "mobileNumPad-likeAirCall-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('5');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-bigNumber"
  }, [_vm._v("5")]), _c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-smallNumber"
  }, [_vm._v("J K L")])]), _c('div', {
    staticClass: "mobileNumPad-likeAirCall-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('6');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-bigNumber"
  }, [_vm._v("6")]), _c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-smallNumber"
  }, [_vm._v("M N O")])]), _c('div', {
    staticClass: "mobileNumPad-likeAirCall-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('7');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-bigNumber"
  }, [_vm._v("7")]), _c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-smallNumber"
  }, [_vm._v("P Q R S")])]), _c('div', {
    staticClass: "mobileNumPad-likeAirCall-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('8');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-bigNumber"
  }, [_vm._v("8")]), _c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-smallNumber"
  }, [_vm._v("T U V")])]), _c('div', {
    staticClass: "mobileNumPad-likeAirCall-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('9');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-bigNumber"
  }, [_vm._v("9")]), _c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-smallNumber"
  }, [_vm._v("W X Y Z")])]), _c('div', {
    staticClass: "mobileNumPad-likeAirCall-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('*');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-bigNumber"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "mobileNumPad-likeAirCall-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('0');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-bigNumber"
  }, [_vm._v("0")]), _c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-smallNumber"
  }, [_vm._v("+")])]), _c('div', {
    staticClass: "mobileNumPad-likeAirCall-button",
    on: {
      "click": function ($event) {
        _vm.$store.state.sip.number += String('#');
        _vm.selected.contact_to_dial.reset();
      }
    }
  }, [_c('span', {
    staticClass: "mobileNumPad-likeAirCall-button-bigNumber"
  }, [_vm._v("#")])]), _c('div'), _c('div', {
    staticClass: "mobileNumPad-likeAirCall-button mb-0"
  }, [_c('vb-icon', {
    staticClass: "mx-auto",
    attrs: {
      "icon": "mobileDialerGreenCall-updatedLikeAirCall-icon",
      "height": "60px",
      "width": "60px"
    },
    on: {
      "click": function ($event) {
        return _vm.dial();
      }
    }
  })], 1), _c('div')])]) : _vm._e()] : _vm._e()], 2)]), _c('SendSmsModal', {
    on: {
      "sms-send": function ($event) {
        _vm.$store.state.sip.number = '';
      }
    }
  }), _c('PricingModal', {
    attrs: {
      "modalName": `${_vm._uid}-callSMSpricing`
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }