var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "PowerDialerContainer",
    staticClass: "PowerDialerContainer"
  }, [_c('h2', {
    staticClass: "d-flex flex-wrap align-items-center justify-content-start"
  }, [_vm.selected.sheet_id ? _c('div', {
    staticClass: "goToPreviousScreen"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        _vm.selected.sheet_id = '';
        _vm.selected.numbers = {};
        _vm.response.numbers = [];
        _vm.conditions.setting = false;
      }
    }
  })], 1) : _vm._e(), _c('span', {
    staticClass: "d-block title ml-2"
  }, [_vm._v("Power dialer")])]), !_vm.selected.sheet_id ? _c('div', {
    staticClass: "PowerDialerContainer-inner"
  }, [_vm._l(_vm.response.sheets, function (sheet) {
    return _c('div', {
      key: sheet.id,
      class: `PowerDialer-row ${sheet.id == _vm.$store.state.power_dialer.sheet_id ? 'flashing' : ''}`,
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          _vm.selected.sheet_id = sheet.id;
          _vm.fetchNumbers();
        }
      }
    }, [_c('div', {
      staticClass: "PowerDialer-row-leftSide"
    }, [_c('b-icon', {
      staticClass: "PowerDialer-contact-icon",
      attrs: {
        "icon": "file-earmark-text-fill"
      }
    }), _c('div', [_c('div', {
      staticClass: "PowerDialer-row-mainText d-flex align-items-center"
    }, [_vm._v(" " + _vm._s(sheet.name) + " "), _c('span', {
      staticClass: "AdmintaggedVersion blackAndWhite mb-0 ml-1 px-2"
    }, [_vm._v(_vm._s(sheet.total_records))])])])], 1), _c('div', {
      staticClass: "PowerDialer-row-rightSide"
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-rightArrow-icon",
      attrs: {
        "icon": "PowerDialer-rightArrow-icon",
        "width": "8.526",
        "height": "14.113"
      }
    })], 1)]);
  }), _vm.api.fetch_sheets.send ? _vm._l(5, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "PowerDialer-row"
    }, [_c('div', {
      staticClass: "PowerDialer-row-leftSide",
      staticStyle: {
        "width": "calc(100% - 40px)"
      }
    }, [_c('b-icon', {
      staticClass: "PowerDialer-contact-icon",
      attrs: {
        "icon": "file-earmark-text-fill"
      }
    }), _vm._m(0, true)], 1), _c('div', {
      staticClass: "PowerDialer-row-rightSide"
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-phone-icon",
      attrs: {
        "icon": "PowerDialer-phone-icon",
        "width": "15.983px",
        "height": "16.002px"
      }
    })], 1)]);
  }) : _vm.isEmpty(_vm.response.sheets) ? _c('div', {
    staticClass: "emptyScreenContainer mt-32px b-0 p-0"
  }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')
    }
  }) : _c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/addressBook.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading mt-32px"
  }, [_vm._v("No record")])]) : _vm._e()], 2) : _c('div', {
    staticClass: "PowerDialerContainer-inner"
  }, [_vm.conditions.setting ? _c('div', {
    staticClass: "w-100 d-flex"
  }) : _c('div', {
    staticClass: "smallCircularGraph-container"
  }, [_c('div', {
    staticClass: "smallCircularGraph"
  }, [_c('div', {
    staticClass: "smallCircularGraph-heading"
  }, [_vm._v("Dialed")]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.stateCount.dialed};--fg:#0FA2A9`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.stateCount.dialed) + "% ")])])])]), _c('div', {
    staticClass: "smallCircularGraph"
  }, [_c('div', {
    staticClass: "smallCircularGraph-heading"
  }, [_vm._v("Canceled")]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.stateCount.canceled};--fg:#FC9E1B`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.stateCount.canceled) + "% ")])])])]), _c('div', {
    staticClass: "smallCircularGraph"
  }, [_c('div', {
    staticClass: "smallCircularGraph-heading"
  }, [_vm._v("Missed")]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.stateCount.missed};--fg:#EF2E33`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.stateCount.missed) + "% ")])])])]), _c('div', {
    staticClass: "smallCircularGraph w-50"
  }, [_c('div', {
    staticClass: "smallCircularGraph-heading"
  }, [_vm._v("Dialing")]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.stateCount.dialing};--fg:#00C268`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.stateCount.dialing) + "% ")])])])]), _c('div', {
    staticClass: "smallCircularGraph w-50"
  }, [_c('div', {
    staticClass: "smallCircularGraph-heading"
  }, [_vm._v("Pending")]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.stateCount.pending};--fg:black;`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_c('span', [_vm._v(_vm._s(_vm.stateCount.pending) + "%")])])])])])]), !_vm.conditions.setting ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-20px"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.$store.state.power_dialer.sheet_id == _vm.selected.sheet_id ? _c('b-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "checked": _vm.$store.state.power_dialer.auto
    },
    on: {
      "change": function ($event) {
        _vm.$store.state.power_dialer.auto ? _vm.$store.state.power_dialer.auto = false : (_vm.$store.state.power_dialer.auto = true, _vm.nextCall());
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "autoSwitchText"
  }, [_vm._v("Auto")])], 1), _c('b-button', {
    staticClass: "clearButton ml-auto",
    attrs: {
      "disabled": _vm.api.fetch_sheet_numbers.send,
      "size": "sm",
      "type": "button",
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.openSetting();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "powerDilerRightSide-clear-icon",
      "width": "21.84",
      "height": "18.719"
    }
  }), _vm._v(" Clear ")], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "PowerDialer-rows-container"
  }, _vm._l(_vm.numbers, function (number) {
    return _c('div', {
      key: number.id,
      class: `PowerDialer-row ${number.state == 'dialing' ? 'flashing' : ''}`
    }, [_c('div', {
      class: `PowerDialer-row-leftSide ${_vm.conditions.setting ? 'insideSettings' : ''}`
    }, [_vm.conditions.setting ? _c('b-button', {
      staticClass: "p-0 mr-2",
      attrs: {
        "disabled": _vm.api.clear_status.send,
        "type": "button",
        "variant": "link"
      },
      on: {
        "click": function ($event) {
          _vm.selected.numbers[number.row_id] ? _vm.$delete(_vm.selected.numbers, number.row_id) : _vm.$set(_vm.selected.numbers, number.row_id, number.row_id);
        }
      }
    }, [_vm.selected.numbers[number.row_id] ? _c('b-icon', {
      attrs: {
        "icon": "check-square-fill",
        "variant": "black"
      }
    }) : _c('b-icon', {
      attrs: {
        "icon": "square",
        "variant": "black"
      }
    })], 1) : _vm._e(), _c('vb-icon', {
      staticClass: "PowerDialer-contact-icon",
      attrs: {
        "icon": "PowerDialer-contact-icon"
      }
    }), _c('div', {
      staticClass: "insideSettings-text-container"
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip",
        value: number.name,
        expression: "number.name"
      }],
      staticClass: "PowerDialer-row-mainText name-chip"
    }, [_vm._v(_vm._s(number.name))]), _c('div', {
      class: `PowerDialer-row-secondaryText ${number.state == 'dialing' ? 'highlightIt' : ''}`
    }, [_vm._v(_vm._s(_vm._f("number_formater")(number.number)))]), number.state ? _c('div', {
      staticClass: "PowerDialer-row-verySmallText"
    }, [_c('span', {
      class: `${number.state}`
    }, [_vm._v(_vm._s(number.state))]), number.state == 'dialing' ? _c('div', {
      staticClass: "d-flex"
    }, [number.dialed_by != _vm.getCurrentUser.account ? _c('div', {
      staticClass: "d-flex mr-2"
    }, [_c('span', {
      staticClass: "mr-1"
    }, [_vm._v("by")]), number.dialed_by ? _c('Info', {
      attrs: {
        "id": number.dialed_by,
        "only_name": true
      }
    }) : _vm._e()], 1) : _vm._e(), _vm._m(1, true)]) : _vm._e()]) : _vm._e()])], 1), !_vm.conditions.setting ? _c('div', {
      staticClass: "PowerDialer-row-rightSide"
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-phone-icon",
      attrs: {
        "icon": "powerDilerRightSideInfo-icon",
        "width": "22.74px",
        "height": "22.74px"
      },
      on: {
        "click": function ($event) {
          return _vm.$modal.show(`${_vm._uid}-ContactInfo`, {
            row_no: number.row_id,
            sheet_id: number.sheet_id
          });
        }
      }
    }), _c('vb-icon', {
      staticClass: "PowerDialer-phone-icon onlyPhoneIcon",
      class: `${number.state == 'dialing' || _vm.$store.state.power_dialer.sheet_id ? 'disabled' : ''}`,
      attrs: {
        "icon": "PowerDialer-phone-icon",
        "width": "15.983px",
        "height": "16.002px"
      },
      on: {
        "click": function ($event) {
          _vm.$store.state.power_dialer.sheet_id || number.state == 'dialing' ? '' : _vm.dialPowerDialerNumber(number);
        }
      }
    })], 1) : _vm._e()]);
  }), 0), _vm.api.fetch_sheet_numbers.send ? _vm._l(5, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "PowerDialer-row"
    }, [_c('div', {
      staticClass: "PowerDialer-row-leftSide",
      staticStyle: {
        "width": "calc(100% - 40px)"
      }
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-contact-icon",
      attrs: {
        "icon": "PowerDialer-contact-icon"
      }
    }), _vm._m(2, true)], 1), _c('div', {
      staticClass: "PowerDialer-row-rightSide"
    }, [_c('vb-icon', {
      staticClass: "PowerDialer-phone-icon",
      attrs: {
        "icon": "PowerDialer-phone-icon",
        "width": "15.983px",
        "height": "16.002px"
      }
    })], 1)]);
  }) : _vm.isEmpty(_vm.numbers) ? _c('div', {
    staticClass: "emptyScreenContainer mt-32px b-0 p-0"
  }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')
    }
  }) : _c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/addressBook.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading mt-32px"
  }, [_vm._v("No record")])]) : _vm._e(), _vm.conditions.setting ? [_c('button', {
    staticClass: "clearStatusFullWidthButton mt-12px",
    attrs: {
      "disabled": _vm.api.clear_status.send
    },
    on: {
      "click": function ($event) {
        return _vm.clearStatus();
      }
    }
  }, [_vm._v(" Clear status ")])] : _vm._e(), _vm.selected.sheet_id ? [_c('div', {
    staticClass: "d-flex mt-20px",
    staticStyle: {
      "gap": "10px"
    }
  }, [_vm.$store.state.power_dialer.sheet_id == _vm.selected.sheet_id ? _c('b-button', {
    staticClass: "AutoCallInfo-button-grey w-50",
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.power_dialer.sheet_id = '';
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "powerDiler-stopAutoDialing-icon",
      "height": "26px",
      "width": "26px"
    }
  }), _vm._v(" Stop call ")], 1) : _c('b-button', {
    staticClass: "AutoCallInfo-button-grey w-50",
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.power_dialer.sheet_id ? '' : _vm.startAutoDial();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "powerDiler-startAutoDialing-icon",
      "height": "26px",
      "width": "26px"
    }
  }), _vm._v(" Auto call ")], 1), !_vm.$store.state.power_dialer.auto ? _c('b-button', {
    staticClass: "AutoCallInfo-button w-50",
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.nextCall();
      }
    }
  }, [_vm._v(" Next Call ")]) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex justify-content-center"
  })] : _vm._e()], 2), _c('PowerDialerContactInfoModal', {
    attrs: {
      "modalName": `${_vm._uid}-ContactInfo`
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "calc(100% - 40px)"
    }
  }, [_c('div', {
    staticClass: "PowerDialer-row-mainText name-chip w-100 mb-1"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "15px",
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "PowerDialer-row-secondaryText w-100"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "13px",
      "width": "100%"
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialingCircles"
  }, [_c('div', {
    staticClass: "dialingCircle"
  }), _c('div', {
    staticClass: "dialingCircle"
  }), _c('div', {
    staticClass: "dialingCircle"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "calc(100% - 40px)"
    }
  }, [_c('div', {
    staticClass: "PowerDialer-row-mainText name-chip w-100 mb-1"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "15px",
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "PowerDialer-row-secondaryText w-100"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "13px",
      "width": "100%"
    }
  })])]);

}]

export { render, staticRenderFns }